import React from 'react';

const IconLoader = () => (
  <svg
    width="283"
    height="257"
    viewBox="0 0 283 257"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M283 40.7998V256.8H267V79.2998L248 98.2994V75.7994L283 40.7998ZM201 122.799L141.5 182.3L0 40.7998V256.8H16V79.2998L141.5 204.8L201 145.299V122.799Z"
      fill="#1B5174"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M46 239.8V133.012L62 149.012V239.8H46ZM83.2645 83.5608L46 46.2963V23.7998L152.497 130.279L141.245 141.532L83.2645 83.5608ZM184.606 184.886L236.729 237V21H220.729V198.5L195.858 173.633L184.606 184.886Z"
      fill="#1B5174"
    />
  </svg>
);

export default IconLoader;
